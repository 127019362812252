import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-fayetteville-north-carolina.png'
import image0 from "../../images/cities/scale-model-of-downtown-fayetteville-tours-in-fayetteville-north-carolina.png"
import image1 from "../../images/cities/scale-model-of-arts-council-of-fayetteville-cumberland-county-in-fayetteville-north-carolina.png"
import image2 from "../../images/cities/scale-model-of-the-fayetteville-area-transportation-and-local-history-museum-in-fayetteville-north-carolina.png"
import image3 from "../../images/cities/scale-model-of-museum-of-the-cape-fear-historical-complex-in-fayetteville-north-carolina.png"
import image4 from "../../images/cities/scale-model-of-honeycutt-park-in-fayetteville-north-carolina.png"
import image5 from "../../images/cities/scale-model-of-n-c-travel-in-fayetteville-north-carolina.png"
import image6 from "../../images/cities/scale-model-of-fayetteville-area-convention-and-visitors-bureau-in-fayetteville-north-carolina.png"
import image7 from "../../images/cities/scale-model-of-fayetteville-public-info-line-in-fayetteville-north-carolina.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Fayetteville'
            state='North Carolina'
            image={image}
            lat='35.0828'
            lon='-78.9735'
            attractions={ [{"name": "Downtown Fayetteville Tours", "vicinity": "215 Gillespie St, Fayetteville", "types": ["point_of_interest", "establishment"], "lat": 35.0496793, "lng": -78.87875689999998}, {"name": "Arts Council of Fayetteville-Cumberland County", "vicinity": "301 Hay St, Fayetteville", "types": ["art_gallery", "point_of_interest", "establishment"], "lat": 35.05337, "lng": -78.88120200000003}, {"name": "The Fayetteville Area Transportation and Local History Museum", "vicinity": "325 Franklin St, Fayetteville", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.0521412, "lng": -78.882184}, {"name": "Museum of the Cape Fear Historical Complex", "vicinity": "801 Arsenal Ave, Fayetteville", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.0545048, "lng": -78.89266550000002}, {"name": "Honeycutt Park", "vicinity": "352 Devers St, Fayetteville", "types": ["park", "point_of_interest", "establishment"], "lat": 35.0683775, "lng": -78.92201339999997}, {"name": "N C Travel", "vicinity": "5052 Yadkin Rd # 104, Fayetteville", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 35.083825, "lng": -78.9702881}, {"name": "Fayetteville Area Convention and Visitors Bureau", "vicinity": "245 Person St, Fayetteville", "types": ["point_of_interest", "establishment"], "lat": 35.051523, "lng": -78.87447800000001}, {"name": "Fayetteville Public Info Line", "vicinity": "433 Hay St, Fayetteville", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 35.05366, "lng": -78.88365199999998}] }
            attractionImages={ {"Downtown Fayetteville Tours":image0,"Arts Council of Fayetteville-Cumberland County":image1,"The Fayetteville Area Transportation and Local History Museum":image2,"Museum of the Cape Fear Historical Complex":image3,"Honeycutt Park":image4,"N C Travel":image5,"Fayetteville Area Convention and Visitors Bureau":image6,"Fayetteville Public Info Line":image7,} }
       />);
  }
}